function offset (el) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
}

if ('addEventListener' in window) {
  window.addEventListener('load', function () {

    let trigger = document.querySelector('.trigger')
    let nav = document.querySelector('.header')

    if (nav != null) {

      let triggerOffset = offset(trigger)

      const doc = document.documentElement
      let top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)

      if (top > triggerOffset.top) {
        nav.classList.add('header--sticky')
      } else {
        nav.classList.remove('header--sticky')
      }

      window.addEventListener('scroll', function (e) {

        // Trigger sticky class on scroll
        const doc = document.documentElement
        let top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)

        if (top > triggerOffset.top) {
          nav.classList.add('header--sticky')
        } else {
          nav.classList.remove('header--sticky')
        }

        // Go for Parallax
        let bannerImage = document.querySelector('.banner__image__image')
        if (bannerImage != null) {
          bannerImage.style = 'transform: translate3d(0, ' + (top / 2) + 'px, 0);'
        }

        let bannerContent = document.querySelector('.banner__content')
        if (bannerContent != null) {
          bannerContent.style = 'transform: translate3d(0, ' + (top / 3) + 'px, 0);'
        }
      })
    }
  })
}


